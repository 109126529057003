import './profile.scss'
import './edit.scss'
import './subscription.scss'
import './components/profile-card.scss'
import './components/profile-tabs.scss'
import './components/tabs/my-feed.scss'
import './components/tabs/my-breaks.scss'
import './components/tabs/alerts.scss'
import './components/tabs/photos.scss'
import './components/tabs/reviews.scss'
import './static/icon-edit.svg'
import './static/icon-user.svg'
import './static/icon-caret-right.svg'
import './static/icon-arrow-up.svg'
import './static/icon-arrow-left.svg'
import './static/icon-heart-red.svg'

import { BaseComponent, BElement } from 'utils/base-component'

// import {
//   getFavourites,
//   favouriteLocation,
//   unFavouriteLocation,
//   updateFavouritesOrder
// } from './favourites'

export class TabNavigationComponent extends BaseComponent {
  static cssClass = 'profile-tabs'
  private tabs!: BElement[]
  private contentSection!: BElement[]

  init() {
    this.tabs = this.elements('tab')
    this.contentSection = this.elements('content-section')
    this.hideAllSections()

    // getFavourites().then((data) => {
    //   console.log(data)
    // })
    // unFavouriteLocation('')
    // favouriteLocation('')
    // updateFavouritesOrder([''])

    this.tabs.forEach((tab, index) => {
      if (index === 0) {
        // on init, add is-active class to first tab
        tab.toggleModifier('is-active')
      }
      // on init, add click listener to tabs
      tab.element.addEventListener('click', () => {
        this.setTab(tab)
      })
    })
  }

  // on init, hide all sections that aren't the first one
  hideAllSections() {
    this.contentSection.forEach((section, index) => {
      if (index > 0) {
        section.toggleModifier('is-hidden')
      } else {
        section.toggleModifier('is-active')
      }
    })
  }

  setTab(clickedTab: BElement) {
    // Find the index of the clicked tab
    const clickedTabIndex = this.tabs.indexOf(clickedTab)

    // Iterate over all tabs, add is-active class to tab that was clicked
    this.tabs.forEach((tab, index) => {
      tab.toggleModifier('is-active', index === clickedTabIndex)
    })

    // Iterate over all sections, toggle is-active on section that corresponds to clicked tab and toggle is-hidden on !clicked
    this.contentSection.forEach((section, index) => {
      section.toggleModifier('is-hidden', index !== clickedTabIndex)
      section.toggleModifier('is-active', index === clickedTabIndex)
    })
  }
}
