import { BaseComponent, BElement } from 'utils/base-component'
import './index.scss'
import './static/icon-close.svg'

export class Banner extends BaseComponent {
  static cssClass = 'questionnaire-banner'

  private close!: BElement
  private open!: BElement
  private CLOSEDCLASS: string

  init() {
    this.close = this.element('close-button')
    this.open = this.element('button')

    this.CLOSEDCLASS = `${Banner.cssClass}--closed`

    if (document.cookie.indexOf('bannerClosed=1') !== -1) {
      this.root.classList.add(this.CLOSEDCLASS)
    }

    this.close.element.addEventListener('click', (e) => {
      e.preventDefault()

      const expiryDate = new Date()
      expiryDate.setMonth(expiryDate.getMonth() + 1)

      document.cookie = `bannerClosed=1; expires=${expiryDate.toUTCString()}; path=/`

      this.root.classList.add(this.CLOSEDCLASS)
    })
  }
}
